import Avr from "avr"
window.Avr = Avr

import * as Turbo from "@hotwired/turbo"

import Rails from "@rails/ujs"
Rails.start()

// https://github.com/hotwired/turbo-rails/issues/135
Rails.delegate(document, Rails.linkDisableSelector,   "turbo:before-cache", Rails.enableElement)
Rails.delegate(document, Rails.buttonDisableSelector, "turbo:before-cache", Rails.enableElement)
Rails.delegate(document, Rails.buttonDisableSelector, "turbo:submit-end", Rails.enableElement)
Rails.delegate(document, Rails.formSubmitSelector, "turbo:submit-start", Rails.disableElement)
Rails.delegate(document, Rails.formSubmitSelector, "turbo:submit-end", Rails.enableElement)
Rails.delegate(document, Rails.formSubmitSelector, "turbo:before-cache", Rails.enableElement)

window.Rails = Rails
window.Turbo = Turbo

import 'alpine-turbo-drive-adapter'
// import 'alpinejs'

import "../assets/stylesheets/logon_page";
